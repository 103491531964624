import { Menu } from '@web-passion/uikit';
import React from 'react';

import MenuLinks from '../Menu/links';
import TitleH3 from '../Title/h3';

import { LightBlue, MainColour } from '../../utils/variables';
import { SidebarCaret } from '../Icons/icons';

import './style.scss';

export default function Sidebar({ data }) {
  return (
    <div
      className="pageSidebar"
      style={{
        backgroundColor: LightBlue,
        padding: `60px 45px 45px`,
      }}
    >
      {data.map((sidebar, i) => {
        const key = `sidebarItem${i}`;
        return (
          <div className="sidebarItem" key={key}>
            <TitleH3 margin="30px" colour={MainColour}>
              {sidebar.header}
            </TitleH3>
            <Menu
              id={`${key}_menu`}
              className="sidebarMenu"
              items={sidebar.links}
              customLink={item => (
                <MenuLinks {...item} icon={<SidebarCaret size="8px" />} />
              )}
            />
          </div>
        );
      })}
    </div>
  );
}
