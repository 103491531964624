import React from 'react';

import Links from '../Links';

import { BorderRadius } from '../../utils/variables';

export default function RelatedLinks({ data }) {
  return (
    <div
      style={{
        backgroundColor: `#f2f2f2`,
        padding: `7px 20px`,
        borderRadius: BorderRadius,
        display: `flex`,
        alignItems: `center`,
        gap: `15px`,
      }}
    >
      Related Links:{' '}
      <ul>
        {data.map((link, i) => {
          const key = `relatedLink${i}`;
          return (
            <li
              key={key}
              style={{
                backgroundColor: `#e5e5e5`,
                padding: `5px 20px`,
                borderRadius: BorderRadius,
              }}
            >
              <Links to={link.link} label={link.title || link.label}>
                {link.label}
              </Links>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
