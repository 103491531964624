import React, { useState } from 'react';

import Links from '../Links';

import { BorderRadius, MainColour } from '../../utils/variables';
import ButtonHidden from '../Button/hidden';

export default function Feedback() {
  const [clicked, setClicked] = useState(false);
  return (
    <div
      style={{
        margin: `60px 0`,
        display: `flex`,
        flexDirection: `column`,
      }}
    >
      <span style={{ color: MainColour, fontWeight: 500 }}>
        Did this advice help?
      </span>
      {!clicked ? (
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            gap: `20px`,
            margin: `15px 0 `,
          }}
        >
          <ButtonHidden onClick={() => setClicked(true)}>
            <div style={buttonStyle}>
              Yes <Circle />
            </div>
          </ButtonHidden>
          <ButtonHidden onClick={() => setClicked(true)}>
            <div style={buttonStyle}>
              No <Circle />
            </div>
          </ButtonHidden>
        </div>
      ) : (
        <p style={{ margin: `15px 0 `, color: `green` }}>
          Thanks for your feedback
        </p>
      )}
      <span style={{ color: MainColour, fontStyle: `italic` }}>
        Is there anything wrong with this page?{' '}
        <Links to="/contact/" style={{ color: MainColour, fontWeight: `bold` }}>
          If so let us know!
        </Links>
      </span>
    </div>
  );
}

const Circle = () => (
  <span
    style={{
      display: `inline-block`,
      backgroundColor: `#fff`,
      border: `4px solid #525252`,
      height: `18px`,
      width: `18px`,
      borderRadius: `18px`,
    }}
  />
);

const buttonStyle = {
  backgroundColor: `#e5e5e5`,
  padding: `10px 30px`,
  borderRadius: BorderRadius,
  display: `flex`,
  alignItems: `center`,
  gap: `15px`,
};
