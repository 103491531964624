import React from 'react';
import { Button } from '@web-passion/uikit';

export default function ButtonHidden({ children, style, ...props }) {
  return (
    <Button {...props} style={style} hidden>
      {children}
    </Button>
  );
}
