import { ButtonBase } from '@web-passion/uikit';
import React from 'react';

import Links from '../Links';

import { MainColour } from '../../utils/variables';

import sidebarCtaBg from '../../images/sidebar-cta.webp';

export default function SidebarCta({ hasMenu, link }) {
  return (
    <div
      className="sidebarCta"
      style={{
        backgroundColor: MainColour,
        marginTop: hasMenu ? `30px` : `60px`,
        padding: `45px 30px`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        position: `relative`,
        overflow: `hidden`,
      }}
    >
      <img
        src={sidebarCtaBg}
        alt="Start your Visa Application"
        style={{
          position: `absolute`,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          opacity: 0.1,
        }}
      />
      <span
        style={{
          fontSize: `30px`,
          lineHeight: 1.4,
          color: `#fff`,
          textAlign: `center`,
          marginBottom: `30px`,
          fontWeight: 500,
          position: `relative`,
          zIndex: 5,
        }}
      >
        Start your Visa Application
      </span>
      <Links to={link}>
        <ButtonBase color="#fff" textColor={MainColour}>
          Start your application
        </ButtonBase>
      </Links>
    </div>
  );
}
