import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import Layout from '../layouts';

import Container from '../components/Container';
import Row from '../components/Container/row';
import Col from '../components/Container/column';
import Placeholder from '../components/Placeholder';
import Breadcrumbs from '../components/Breadcrumbs';

import Links from '../components/Links';
import TitleH2 from '../components/Title/h2';

import '../components/Blog/post.css';
import '../components/Subpage/style.css';

import { MainColour } from '../utils/variables';
import { TopRightArrow } from '../components/Icons/icons';

import loadComponents from '../components/Loadable';
import Feedback from '../components/Feedback';
import RelatedLinks from '../components/RelatedLinks';
import Sidebar from '../components/Sidebar';
import SidebarCta from '../components/Sidebar/cta';

const Share = loadComponents('share');
const SubpageHeader = loadComponents('subpage-header');
const PostMeta = loadComponents('blog-meta');

export default function PostTemplate({ data, location }) {
  const prevPage = location.state && location.state.prevPage;

  const post = data.wpPost;
  const pageTitle = post.seo && post.seo.title ? post.seo.title : post.title;
  const featuredImage =
    post.images.featuredImage && post.images.featuredImage.localFile;

  const mainCategory = post.categories.nodes[0];

  return (
    <Layout
      title={pageTitle}
      seo={post.seo}
      location={post.link}
      prevPage={prevPage}
      padding="0 0 60px"
    >
      <article itemScope itemType="https://schema.org/Article">
        <SubpageHeader
          image={featuredImage?.postImage?.gatsbyImageData}
          title={post.title}
          date={post.date}
          categories={post.categories}
        />
        <Container>
          <Row justify="space-between" rowGap="45px">
            <Col xl={{ width: `65%` }} className="contentOuter">
              <Breadcrumbs
                data={[
                  { label: 'Home', url: '/' },
                  { label: mainCategory.name, url: mainCategory.link },
                  { label: post.title, url: post.link },
                ]}
              />
              <section className="pageContent blog">
                <div className="content">{parse(post.content)}</div>
              </section>
              <div className="postFooter" style={footerStyle}>
                <PostMeta
                  date={post.date}
                  categories={post.categories}
                  // margin="0px"
                />
                <Share
                  title={post.title}
                  fallback={
                    <Placeholder width="285px" height="24px" colour="#222" />
                  }
                />
                <Feedback />
                {post?.subpageLayout?.relatedLinks?.links?.length > 0 && (
                  <RelatedLinks
                    data={post?.subpageLayout?.relatedLinks?.links}
                  />
                )}
              </div>
            </Col>
            <Col
              xxl={{ width: `30%` }}
              xl={{ width: `35%` }}
              className="pageSidebar"
            >
              {post.subpageLayout?.sidebar?.length > 0 && (
                <Sidebar data={post.subpageLayout.sidebar} />
              )}
              {post.sidebarCta.showCta && (
                <SidebarCta
                  hasMenu={post.subpageLayout.sidebar?.length > 0}
                  link={post.sidebarCta.link}
                />
              )}
            </Col>
          </Row>
          {post.subpageLayout.footerColumns?.length > 0 && (
            <Row
              className="footerColumns"
              justify="space-between"
              rowGap="45px"
            >
              {post.subpageLayout.footerColumns.map((col, i) => {
                const key = `footerColumns${i}`;
                return (
                  <Col key={key} xl={{ width: `32%` }}>
                    <Links
                      to={col.link}
                      label={col.title}
                      className="columnHeader"
                      style={{
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: `space-between`,
                      }}
                    >
                      <TitleH2
                        size="32px"
                        margin="0px"
                        line={1}
                        colour={MainColour}
                      >
                        {col.title}
                      </TitleH2>
                      <TopRightArrow />
                    </Links>
                    <p>{parse(col.text)}</p>
                    <Links
                      to={col.link}
                      label={col.action}
                      className="columnAction"
                    >
                      <strong>{col.action} &gt;</strong>
                    </Links>
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>
      </article>
    </Layout>
  );
}

const footerStyle = {
  margin: `60px 0 0 0`,
};

export const postsQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      ...PostData
      ...PostSeo
      ...BlogImages
      sidebarCta {
        showCta
        link
      }
      subpageLayout {
        relatedLinks {
          links {
            link
            label
            title
          }
        }
        sidebar {
          header
          links {
            label
            link
            title
          }
        }
        footerColumns {
          title
          text
          link
          action
        }
      }
    }
  }
`;
